@import "src/colors";

.loadingPageContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px;
  background: #005072;

  .loadingPageContent {
    width: 500px;

    .logoContainer {
      text-align: center;
      padding-bottom: 20px;
    }


    }

}
