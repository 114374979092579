@import "src/colors";

.headerContainer {
  display: flex;
  justify-content: space-between;
  background: $headerBackgroundColor !important;
  padding: 0 20px 0 0 !important;

  .rightSection {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
}

.headerContainer.dark-mode {
  background: $headerBackgroundColor_DarkMode !important;
}
