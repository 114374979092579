.ant-carousel .slick-dots li button {
  background: #4b5f91;
  opacity: 0.2;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: #4b5f91;
}
.ant-carousel .slick-dots li{
  padding-bottom:60px;
}

.ant-descriptions-bordered .ant-descriptions-view{
  border-width: 3px;
}


td.carouselButtons.ant-descriptions-item-content{
  display: flex;
  flex-direction: row-reverse;
}
.dark-mode {
  .ant-carousel .slick-dots li button {
    background: #ffffff;
    //opacity: 0.2;
  }

  .ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    background: #ffffff;
  }
  .ant-carousel .slick-dots li{
    padding-bottom:60px;
    }

  .ant-descriptions-bordered .ant-descriptions-view{
    border-width: 3px;
  }
}