.logo {
  margin-bottom: 10px;
}

.sider {
  overflow: auto;
  height: 100vh;
  left:0;
  top: 0;
  bottom: 0;
}

.content {
  margin: 24px 16px 0;
  overflow: initial;
}

.footer {
  text-align: center;
  position: sticky;
  bottom: 0;
  width: 100%;
}
::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}



::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(93, 92, 92, 0.5);
  border-radius: 10px;
  background-color: #d4d4d4;
  /*border: 1px solid #36393b;*/
}



::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(93, 92, 92, 0.5);
  background-color: rgba(93, 92, 92, 0.5);
}
