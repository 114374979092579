@import "src/colors";

.loginPageContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .loginPageLanguageDropdown {
    border-width: 0;
    width:120px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    color:white;
    border:0;
    border-radius: 8px;
  }

  .ant-select:is(.ant-select-customize-input) .ant-select-selector{
    color:white;
    border:0;
    border-radius: 8px;
  }

  .ant-select-selection-placeholder {
    color: #000000;
  }


  padding: 20px;
  background: #005072;

  .loginPageContent {
    width: 500px;

    .logoContainer {
      text-align: center;
      padding-bottom: 20px;
    }

    .loginForm {
      border: 1px solid rgb(238, 238, 238);
      padding: 20px 20px 10px;
      border-radius: 8px;
      box-shadow: rgb(0 0 0 / 15%) 2px 2px 20px 8px;
      background-color: #ffffff;
      align-content: center;
      justify-content: center;

      .forgotPasswordButton {
        padding: 0
      }

      .forgotPasswordContainer {
        display: flex;
        justify-content: flex-end;
      }

      .currentPasswordContainer {
        margin-bottom: 0 !important;
      }

      .loginButton {
        width: 200px;
        background-color: rgb(0, 100, 142);
        border-radius: 6px;
        margin-bottom:10px;
      }
      .ant-form-item-label{
        text-align:left;
      }
      .ant-form-item{
        margin-bottom:13px;
      }
    }
  }
}

.loginPageContainer.dark-mode {
  .loginForm {
    border-color: $borderColor_DarkMode;
    box-shadow: $formBoxShadow_DarkMode;
    background-color: $formBackgroundColor_DarkMode;
  }

  .loginPageLanguageDropdown {
    background-color: $formBackgroundColor_DarkMode;
    border-width: 0;
    border-radius:8px;
  }

    .ant-select-selection-placeholder {
      color: #FFFFFF;
    }

}
