.wrapper-row{
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;

}
.wrapper-column{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  .right-positioned-padded-items{
    margin-top:20px;
    margin-left: 0px !important;
  }
  .limitedMaxWidth{
    max-width:100% !important;
  }
}